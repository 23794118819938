








































































































import { Component, Vue, Watch } from "vue-property-decorator";
import LazyComponent from "v-lazy-component/vue2";

@Component({
  components: {
    LazyComponent,
    Banners: () =>
      import(
        /* webpackChunkName: "Banners" */ "@/components/Banners/BannerComponent.vue"
      ),
    Enlaces: () =>
      import(
        /* webpackChunkName: "EnlacesInteres" */ "@/components/EnlacesInteres/EnlacesInteresComponent.vue"
      ),
    Noticias: () =>
      import(
        /* webpackChunkName: "Noticias" */ "@/components/NoticiasHome/NoticiasComponent.vue"
      ),
    SesionInforma: () =>
      import(
        /* webpackChunkName: "SesiónInforma" */ "@/components/SesionMunicipio/SesionInformaComponent.vue"
      ),
    Actividades: () =>
      import(
        /* webpackChunkName: "Actividades" */ "@/components/Actividades/ActividadesHome.vue"
      ),
    Galeria: () =>
      import(
        /* webpackChunkName: "Galeria" */ "@/components/Galeria/GaleriaHome.vue"
      ),
  },
})
export default class Home extends Vue {
  private slidePopUp = 0;

  private popUp: any = null;
  private verPopUp = false;
  private verPopUpMultiple = false;
  private verBanner = false;

  private created() {
    this.getPopUp();
  }

  private mounted() {
    // let recaptchaScript = document.createElement("script");
    // recaptchaScript.setAttribute(
    //   "src",
    //   "https://platform.twitter.com/widgets.js"
    // );
    // document.head.appendChild(recaptchaScript);
    // let recaptchaScriptInsta = document.createElement("script");
    // recaptchaScriptInsta.setAttribute(
    //   "src",
    //   "https://cdn.lightwidget.com/widgets/lightwidget.js"
    // );
    // document.head.appendChild(recaptchaScriptInsta);
    // let recaptchaScriptFb = document.createElement("script");
    // recaptchaScriptFb.setAttribute(
    //   "src",
    //   "https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v15.0"
    // );
    // document.head.appendChild(recaptchaScriptFb);
  }

  private getPopUp() {
    this.$axios
      .get("popup")
      .then((res) => {
        let base = process.env.VUE_APP_BASE_URL;

        this.popUp = res.data;
        for (let i = 0; i < this.popUp.length; i++) {
          if (this.popUp[i].imagen != "") {
            this.popUp[i].imagen = base + this.popUp[i].imagen;
          } else {
            this.popUp[i].imagen = require("@/assets/logos/logo300.webp");
          }
        }
        if (this.popUp.length == 1) {
          this.verPopUp = true;
          this.verPopUpMultiple = false;
        } else {
          this.verPopUp = false;
          this.verPopUpMultiple = true;
        }
      })
      .catch((err: any) => {
        this.popUp = [];
        this.verPopUp = false;
        this.verPopUpMultiple = false;
      });
  }

  private clickPopUp(popUp) {
    if (popUp && popUp.url && popUp.url != "") {
      window.open(popUp.url, "_blank");
    }
  }

  private showBanner(info) {
    if (info.banner == true) {
      this.verBanner = true;
    }
  }
}
